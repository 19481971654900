import { useEffect, useState } from "react";

import "./App.css";

function App() {
  const [token, setToken] = useState();
  const [refreshToken, setRefreshToken] = useState();
  //const APIURL = "http://api.cundle.com";
  const APIURL = "https://api.cundle.com";
  //const APIURL = "http://localhost:29706";

  async function loginUser(credentials) {
    console.log(JSON.stringify(credentials));
    const response = await fetch(`${APIURL}/api/Authentication/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });
    const data = await response.json();
    console.log({ data });
    setToken(data?.token);
    setRefreshToken(data?.refreshToken);
    return { data };
  }

  async function getAircraft(e, token) {
    e.preventDefault();
    console.log(token);
    const response = await fetch(`${APIURL}/api/Aircraft`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: ` Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log(JSON.stringify(data));
    return JSON.stringify(data);
  }

  async function setAircraft(e, token) {
    e.preventDefault();
    console.log(token);
    const response = await fetch(`${APIURL}/api/Aircraft`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: ` Bearer ${token}`,
      },
      body: JSON.stringify({
        serialNumber: "15076341",
        registration: "C-GCPU",
        make: "Cessna",
        model: "150N",
        year: 1977,
      }),
    });
    const data = await response.json();
    console.log(JSON.stringify(data));
    return JSON.stringify(data);
  }

  //b0ddc283-36e4-4dca-a5e2-08db2176ad02
  async function updateAircraft(e, token) {
    e.preventDefault();
    console.log(token);
    const response = await fetch(
      `${APIURL}/api/Aircraft/6ac90a32-c850-44b2-a5e4-08db2176ad02`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${token}`,
        },
        body: JSON.stringify({
          id: "6ac90a32-c850-44b2-a5e4-08db2176ad02",
          serialNumber: "15076344",
          registration: "C-ZCPU",
          make: "Cessna",
          model: "150O",
          year: 1978,
        }),
      }
    );
    const data = await response.json();
    console.log(JSON.stringify(data));
    return JSON.stringify(data);
  }

  async function getUsers(e, token) {
    e.preventDefault();
    console.log(token);
    const response = await fetch(`${APIURL}/api/Setup/getallusers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: ` Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log(JSON.stringify(data));
    return JSON.stringify(data);
  }

  async function getRoles(e, token) {
    e.preventDefault();
    console.log(token);
    const response = await fetch(`${APIURL}/api/Setup/getallroles`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: ` Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log(JSON.stringify(data));
    return JSON.stringify(data);
  }

  useEffect(() => {
    loginUser({
      email: "Test1@test.com",
      password: "@Test1Test1",
    });
  }, []);

  return (
    <div className="App">
      <div>{token}</div>
      <div>{refreshToken}</div>
      <button onClick={(e) => getAircraft(e, token)}>Get Aircraft</button>
      <button onClick={(e) => setAircraft(e, token)}>Set Aircraft</button>
      <button onClick={(e) => updateAircraft(e, token)}>Update Aircraft</button>
      <button onClick={(e) => getUsers(e, token)}>Get Users</button>
      <button onClick={(e) => getRoles(e, token)}>Get Roles</button>
    </div>
  );
}

export default App;
